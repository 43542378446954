import svg4everybody from 'svg4everybody';
import { version } from 'util/detect/ie-detect';

/**
 * Polyfills SVG usage for older browsers and helps using the browser cache for symbols.
 */
document.addEventListener('DOMContentLoaded', () => {
    const $$container = document.createElement('div');
    $$container.setAttribute('aria-hidden', 'true');
    $$container.className = 'u-icon-sprite';

    const $body = document.querySelector('body');
    const $svg = document.querySelector('img.u-icon-source');

    const url = $svg.getAttribute('data-src');
    const urlConnector = ~url.indexOf('?') ? '&' : '?';
    const cacheBustingUrl = `${ url }${ urlConnector }`;

    if (!$svg) {
        console.warn(`
            utils/fixes/svg-symbols: No distinct spritemap source found.
            selector: ${ $svg.selector }
            occurrences: ${ $svg.length }
        `);
    }

    // Load the SVG separately without cache
    const SVGRequest = new XMLHttpRequest();

    SVGRequest.addEventListener('load', onLoadSVG);
    SVGRequest.addEventListener('error', onErrorSVG);
    SVGRequest.addEventListener('abort', onErrorSVG);

    SVGRequest.open('GET', cacheBustingUrl, true);
    SVGRequest.send();


    // Handle the loaded, cache-busted SVG
    function onLoadSVG() {
        $$container.innerHTML = this.responseText;
        $body.insertBefore($$container, $body.firstChild);

        if (version >= 9 && version <= 11) {
            // it is save now to exchange the use tags for IE9+
            svg4everybody({
                polyfill: true,
            });
        } else {
            svg4everybody();
        }

        globals.state.svgSymbolsLoaded = true;
    }

    function onErrorSVG(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
    }
});
